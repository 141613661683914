import axios from 'axios';

// requestUrl: 'https://work.goh8.com',
// var requestUrl = 'http://192.168.0.30:8082';
// var requestUrl = 'http://192.168.0.222:8084';
// var requestUrl = 'http://192.168.1.5:8084';
// var requestUrl = 'http://jianzhi.goh8.com:8080';
// var requestUrl = 'https://www.huitao520.top';
// var requestUrl = 'http://www.huitao560.top';
// var requestUrl = 'https://test.level-job.com';
// var requestUrl = 'https://mall.level-job.com';
var requestUrl = 'https://common.level-job.com';

export default class Api {

  static getInstance() {

    if (!Api.instance) {

      Api.instance = new Api();
    }
    return Api.instance;
  }
  // 目录
  menuList(params) {
    return this.callback('/admin/menu/menuList.action', params);
  }
  menuInfo(params) {
    return this.callback('/admin/menu/publishMenu.action', params);
  }
  doPublishMenu(params) {
    return this.callback('/admin/menu/doPublishMenu.action', params);
  }
  doModifyMenu(params) {
    return this.callback('/admin/menu/doModifyMenu.action', params);
  }
  doDeleteMenuList(params) {
    return this.callback('/admin/menu/doDeleteMenuList.action', params);
  }
  // 登录
  doGetVeryCode(params) {
    return this.callback('/admin/login/doGetVeryCode.action', params);
  }
  doAccountLogin(params) {
    return this.callback('/admin/login/accountLogin.action', params);
  }
  doTokenLogin(params) {
    return this.callback('/admin/login/tokenLogin.action', params);
  }
  // 订单模块
  doOrderList(params) {
    return this.callback('/admin/order/queryOrder.action', params);
  }
  doOrderInfo(params) {
    return this.callback('/admin/order/doOrderInfo.action', params);
  }
  // 查询物流信息
  doExpressInfo(params) {
    return this.callback('/admin/order/doExpressInfo.action', params);
  }
  doOrderSuccess(params) {
    return this.callback('/admin/order/orderSuccess.action', params);
  }
  queryPostCompany(params) {
    return this.callback('/admin/postCompany/queryPostCompany.action', params);
  }
  doOrderExpress(params) {
    return this.callback('/admin/order/orderExpress.action', params);
  }
  doModifyExpress(params) {
    return this.callback('/admin/order/doModifyExpress.action', params);
  }
  doCancelOrder(params) {
    return this.callback('/admin/order/cancelOrder.action', params);
  }
  doRefundOrder(params) {
    return this.callback('/admin/order/refundOrder.action', params);
  }
  refundOrderAll(params) {
    return this.callback('/admin/order/refundOrderAll.action', params);
  }
  acceptRefundOrder(params) {
    return this.callback('/admin/order/acceptRefundOrder.action', params);
  }
  refuseRefundOrder(params) {
    return this.callback('/admin/order/refuseRefundOrder.action', params);
  }
  doModifyPayAmount(params) {
    return this.callback('/admin/order/doModifyPayAmount.action', params);
  }
  doBathExport(params) {
    return this.callback('/admin/order/doBathExport.action', params);
  }
  doAllExport(params) {
    return this.callback('/admin/order/doAllExport.action', params);
  }
  // 提现模块
  doTakeCashList(params) {
    return this.callback('/admin/takeCash/queryTakeCash.action', params);
  }
  doTakeCashInfo(params) {
    return this.callback('/admin/takeCash/doTakeCashInfo.action', params);
  }
  doPassTakeCash(params) {
    return this.callback('/admin/takeCash/passTakeCash.action', params);
  }
  doRefuseTakeCash(params) {
    return this.callback('/admin/takeCash/refuseTakeCash.action', params);
  }
  // 商品模块
  doProductList(params) {
    return this.callback('/admin/product/queryProduct.action', params);
  }
  doProductInfo(params) {
    return this.callback('/admin/product/doProductInfo.action', params);
  }
  doSkuInfo(params) {
    return this.callback('/admin/product/doSkuInfo.action', params);
  }
  merchantSelector(params) {
    return this.callback('/admin/merchant/merchantSelector.action', params);
  }
  doPublishProduct(params) {
    return this.callback('/admin/product/doPublishProduct.action', params);
  }
  doModifyProduct(params) {
    return this.callback('/admin/product/doModifyProduct.action', params);
  }
  doPublishSku(params) {
    return this.callback('/admin/product/doPublishSku.action', params);
  }
  doModifySku(params) {
    return this.callback('/admin/product/doModifySku.action', params);
  }
  doDeleteProduct(params) {
    return this.callback('/admin/product/doDeleteProduct.action', params);
  }
  doDeleteSku(params) {
    return this.callback('/admin/product/doDeleteSku.action', params);
  }
  doDisplayProduct(params) {
    return this.callback('/admin/product/doDisplayProduct.action', params);
  }
  doCloseProduct(params) {
    return this.callback('/admin/product/doCloseProduct.action', params);
  }
  doModifyProductData(params) {
    return this.callback('/admin/product/doModifyProductData.action', params);
  }
  //用户管理
  doCustomerList(params) {
    return this.callback('/admin/customer/doCustomerList.action', params);
  }
  modifyCustomer(params) {
    return this.callback('/admin/customer/modifyCustomer.action', params);
  }
  doModifyCustomer(params) {
    return this.callback('/admin/customer/doModifyCustomer.action', params);
  }
  charge(params) {
    return this.callback('/admin/customer/charge.action', params);
  }
  doCharge(params) {
    return this.callback('/admin/customer/doCharge.action', params);
  }
  userOpenidList(params) {
    return this.callback('/admin/userOpenid/doUserOpenidList.action', params);
  }
  basicUserList(params) {
    return this.callback('/admin/basicUser/doBasicUserList.action', params);
  }
  //商家管理
  doPublishMerchant(params) {
    return this.callback('/admin/merchant/doPublishMerchant.action', params);
  }
  queryMerchant(params) {
    return this.callback('/admin/merchant/queryMerchant.action', params);
  }
  doModifyMerchant(params) {
    return this.callback('/admin/merchant/doModifyMerchant.action', params);
  }
  doDeleteMerchant(params) {
    return this.callback('/admin/merchant/doDeleteMerchant.action', params);
  }
  doAccountDetailList(params) {
    return this.callback('/admin/accountDetail/doAccountDetailList.action', params);
  }
  /* 淘客订单 */
  doTbkOrderList(params) {
    return this.callback('/admin/tbkOrder/doTbkOrderList.action', params);
  }
  modifyTbkOrder(params) {
    return this.callback('/admin/tbkOrder/modifyTbkOrder.action', params);
  }
  doModifyTbkOrder(params) {
    return this.callback('/admin/tbkOrder/doModifyTbkOrder.action', params);
  }
  /* 淘客订单-已完成按钮 */
  doSuccessTbkOrder(params) {
    return this.callback('/admin/tbkOrder/doSuccessTbkOrder.action', params);
  }
  /** 模板消息 */
  moduleMessageList(params) {
    return this.callback('/admin/moduleMessage/moduleMessageList.action', params);
  }
  /** 短信消息 */
  messageList(params) {
    return this.callback('/admin/message/messageList.action', params);
  }
  /** 平台参数设置 */
  doSettingInfo(params) {
    return this.callback('/admin/setting/doSettingInfo.action', params);
  }
  doModifySetting(params) {
    return this.callback('/admin/setting/doModifySetting.action', params);
  }
  // 管理员
  adminList(params) {
    return this.callback('/admin/admin/adminList.action', params);
  }
  adminSelector(params) {
    return this.callback('/admin/admin/adminSelector.action', params);
  }
  adminInfo(params) {
    return this.callback('/admin/admin/publishAdmin.action', params);
  }
  doPublishAdmin(params) {
    return this.callback('/admin/admin/doPublishAdmin.action', params);
  }
  doModifyAdmin(params) {
    return this.callback('/admin/admin/doModifyAdmin.action', params);
  }
  doDeleteAdminList(params) {
    return this.callback('/admin/admin/doDeleteAdminList.action', params);
  }
  doReportPlatformList(params) {
    return this.callback('/admin/reportPlatform/doReportPlatformList.action', params);
  }
  doReportXianzhiList(params) {
    return this.callback('/admin/reportXianzhi/doReportXianzhiList.action', params);
  }
  doXianzhiItemList(params) {
    return this.callback('/admin/xianzhiItem/doXianzhiItemList.action', params);
  }
  doModifyXianzhiItem(params) {
    return this.callback('/admin/xianzhiItem/doModifyXianzhiItem.action', params);
  }
  doDeleteXianzhiItem(params) {
    return this.callback('/admin/xianzhiItem/doDeleteXianzhiItem.action', params);
  }
  doModifyXianzhiItemStatus(params) {
    return this.callback('/admin/xianzhiItem/doModifyXianzhiItemStatus.action', params);
  }
  doModifyXianzhiExtendStatus(params) {
    return this.callback('/admin/xianzhiItem/doModifyXianzhiExtendStatus.action', params);
  }
  modifyXianzhiItem(params) {
    return this.callback('/admin/xianzhiItem/modifyXianzhiItem.action', params);
  }
  // 用户升级模块
  customerLevelApplyList(params) {
    return this.callback('/admin/customerLevelApply/queryCustomerLevelApply.action', params);
  }
  doCustomerLevelApplyInfo(params) {
    return this.callback('/admin/customerLevelApply/doCustomerLevelApplyInfo.action', params);
  }
  doPassCustomerLevelApply(params) {
    return this.callback('/admin/customerLevelApply/passCustomerLevelApply.action', params);
  }
  doRefuseCustomerLevelApply(params) {
    return this.callback('/admin/customerLevelApply/refuseCustomerLevelApply.action', params);
  }
  /* 数据字典 */
  dictionaryListAdmin(params) {
      return this.callback('/admin/dictionary/dictionaryList.action', params);
  }
  dictionaryInfo(params) {
      return this.callback('/admin/dictionary/publishDictionary.action', params);
  }
  doPublishDictionary(params) {
      return this.callback('/admin/dictionary/doPublishDictionary.action', params);
  }
  doModifyDictionary(params) {
      return this.callback('/admin/dictionary/doModifyDictionary.action', params);
  }
  queryDictionaryInfo(params) {
      return this.callback('/admin/dictionary/queryDictionaryInfo.action', params);
  }
  doDeleteDictionaryList(params) {
      return this.callback('/admin/dictionary/doDeleteDictionaryList.action', params);
  }
  /* 聊天消息 */
  chatMsgList(params) {
      return this.callback('/admin/chatMsg/chatMsgList.action', params);
  }
  // 淘宝账号设置
  tbClientList(params) {
    return this.callback('/admin/tbClient/tbClientList.action', params);
  }
  publishTbClient(params) {
    return this.callback('/admin/tbClient/publishTbClient.action', params);
  }
  doPublishTbClient(params) {
    return this.callback('/admin/tbClient/doPublishTbClient.action', params);
  }
  doModifyTbClient(params) {
    return this.callback('/admin/tbClient/doModifyTbClient.action', params);
  }
  doDeleteTbClientList(params) {
    return this.callback('/admin/tbClient/doDeleteTbClientList.action', params);
  }
  /** 上架 */
  doTbClientOn(params) {
    return this.callback('/admin/tbClient/doTbClientOn.action', params);
  }
  /** 下架 */
  doTbClientOff(params) {
    return this.callback('/admin/tbClient/doTbClientOff.action', params);
  }

  // 淘宝账号设置
  weixinClientList(params) {
    return this.callback('/admin/weixinClient/weixinClientList.action', params);
  }
  publishWeixinClient(params) {
    return this.callback('/admin/weixinClient/publishWeixinClient.action', params);
  }
  doPublishWeixinClient(params) {
    return this.callback('/admin/weixinClient/doPublishWeixinClient.action', params);
  }
  doModifyWeixinClient(params) {
    return this.callback('/admin/weixinClient/doModifyWeixinClient.action', params);
  }
  doDeleteWeixinClientList(params) {
    return this.callback('/admin/weixinClient/doDeleteWeixinClientList.action', params);
  }
  doAreaSettingList(params) {
    return this.callback('/admin/areaSetting/doAreaSettingList.action', params);
  }
  doPublishAreaSetting(params) {
    return this.callback('/admin/areaSetting/doPublishAreaSetting.action', params);
  }
  modifyAreaSetting(params) {
    return this.callback('/admin/areaSetting/modifyAreaSetting.action', params);
  }
  doModifyAreaSetting(params) {
    return this.callback('/admin/areaSetting/doModifyAreaSetting.action', params);
  }
  doDeleteAreaSetting(params) {
    return this.callback('/admin/areaSetting/doDeleteAreaSetting.action', params);
  }
  /* 操作日志 */
  logList(params) {
      return this.callback('/admin/log/logList.action', params);
  }
  queryLogInfo(params) {
      return this.callback('/admin/log/queryLogsInfo.action', params);
  }
  attributeList(params) {
    return this.callback('/admin/attribute/attributeList.action', params);
  }
  doPublishAttribute(params) {
    return this.callback('/admin/attribute/doPublishAttribute.action', params);
  }
  doDeleteAttributeList(params) {
    return this.callback('/admin/attribute/doDeleteAttributeList.action', params);
  }

  // 微信素材管理
  weixinMediaList(params) {
    return this.callback('/admin/weixinMedia/weixinMediaList.action', params);
  }
  doUploadMedia(params) {
    return this.callback('/admin/weixinMedia/doUploadMedia.action', params);
  }
  doDeleteWeixinMediaList(params) {
    return this.callback('/admin/weixinMedia/doDeleteWeixinMediaList.action', params);
  }

  getUrl(){
    return requestUrl;
  }

  callback(url, data) {
    return new Promise((resolve, reject) => {
      if (url.indexOf("thirdparty") != -1) {
        url = "http://192.168.1.118:8082" + url;
      } else {
        if (url.substr(0, 4) !== 'http') {
          url = requestUrl + url;
        }
      }
      axios({
        url: url,
        data: data,
        method: 'POST',
        header: {
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.data) {
          resolve(res.data);
        }
      }).catch((error) => {
        resolve({ success: false, error: "服务器连接失败" })
      })
    });
  }
}